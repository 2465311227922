import { Backdrop, Box, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WarningDiamondIcon from '../../../icons/WarningDiamondIcon';
import { ClaimContext } from "../../../utlis/ClaimContext";
import { useConvertDto } from '../../../utlis/useConvertDto';
import Widget from '../Widget';
import ConfidenceWidget from "./ConfidenceWidget";
import OverviewWidget from './OverviewWidget';

const Overview = ({ claimProps }) => {
    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { claim } = useContext(ClaimContext);

    const [carCondition, setCarCondition] = useState(false);
    const [phvCondition, setPhvCondition] = useState(false);
    const [noClaim, setNoClaim] = useState(false);
    const [personalInjury, setPersonalInjury] = useState(false);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    const { getClaimIdentification, getClaimClassification, getClaimData, getClaimDescription, getClaimCar, getClaimPhv, getClaimPayment, getClaimOverview, getClaimConfidence } = useConvertDto();

    useEffect(() => {
        if (claim?.claimUUID || claimProps?.claimUUID) {
            setNoClaim(claim?.flags?.claim ? false : true);
            setPersonalInjury(claim?.flags?.personalInjury ?? false);
            if (claim?.type === "PROPERTY") {
                setPhvCondition(true);
            };
            if (claim?.type === "VEHICLE") {
                setCarCondition(true);
            };
            setLoading(false);
        };
        //eslint-disable-next-line
    }, [claim]);

    //eslint-disable-next-line
    const MobileConfidence = () => {
        return (
            <Box
                sx={{
                    display: belowsm ? "flex" : "none",
                    borderRadius: "6px",
                    width: "100%",
                    my: 1,
                    px: 1,
                    border: 1,
                    borderColor: theme.palette.mode === "dark" ? "rgba(139, 0, 0, .35)" : "#FFD9D9"
                }}
            >
                <WarningDiamondIcon />
                <Typography
                    sx={{
                        color: theme.palette.mode === "dark" ? "text.primary" : theme.palette.complete.primary,
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        p: .5
                    }}
                >
                    {t("confidence")}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                    sx={{
                        color: theme.palette.mode === "dark" ? "text.secondary" : theme.palette.complete.secondary,
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        p: .5
                    }}
                >
                    {t("reliable", { percentage: claimProps?.riskConfidence ? claimProps.riskConfidence * 100 : 0 })}
                </Typography>
                <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
                    <CircularProgress
                        variant="determinate"
                        value={claimProps?.riskConfidence ? claimProps.riskConfidence * 100 : 0}
                        size={16}
                        sx={{
                            zIndex: 1,
                            color: claimProps?.riskConfidence ? claimProps.riskConfidence > .5 ? theme.palette.mode === "dark" ? theme.palette.success.main : theme.palette.complete.progress : claimProps.riskConfidence > .2 ? theme.palette.warning.main : theme.palette.error.main : theme.palette.error.main
                        }}
                    />
                    <CircularProgress
                        variant="determinate"
                        value={100}
                        size={16}
                        sx={{
                            ml: -2,
                            color: theme.palette.mode === "dark" ? "transparent" : theme.palette.complete.progressBackground
                        }}
                    />
                </Box>
            </Box>
        );
    };

    if (loading) {
        return (
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    if (noClaim) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "normal" }}>Diese Meldung ist keine Schadenmeldung.</Typography>
            </Box>
        )
    };

    if (personalInjury) {
        return (
            <Box sx={{ width: belowsm ? "90vw" : "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant={belowmd ? "h6" : "h5"} color={theme.palette.text.primary} sx={{ textAlign: "center", fontWeight: "normal" }}>Meldungen mit Hinweisen auf Personenschäden können nicht verarbeitet werden.</Typography>
            </Box>
        )
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ pb: 2 }}>
            {/* <MobileConfidence /> */}
            <Grid item xs={12} >
                <OverviewWidget obj={getClaimOverview()} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Widget obj={getClaimIdentification()} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Grid container>
                    <Widget obj={getClaimClassification()} initialOpen={true} size={{ left: false, right: true }} />
                    <Widget obj={getClaimData()} initialOpen={true} size={{ left: true, right: false }} />
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Widget obj={getClaimDescription()} fullSize={true} />
            </Grid>
            <Grid item xs={12} sx={{ mt: carCondition ? 2 : 0 }}>
                <Widget obj={getClaimCar()} display={carCondition} />
            </Grid>
            <Grid item xs={12} sx={{ mt: phvCondition ? 2 : 0 }}>
                <Widget obj={getClaimPhv()} display={phvCondition} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Widget obj={getClaimPayment()} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <ConfidenceWidget obj={getClaimConfidence()} />
            </Grid>
        </Grid>
    );
};

export default Overview;