import { Backdrop, CircularProgress, Collapse, Container, Grid, IconButton, List, ListItemButton, ListItemText, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useAuth } from 'react-oidc-context';
import { useLocation } from "react-router-dom";
import TriangleIcon from '../../icons/Triangle';
import noPreviewDark from '../../images/no-preview-available-dark.png';
import noPreviewLight from '../../images/no-preview-available-light.png';
import { ClaimContext } from '../../utlis/ClaimContext';
import { useAxios } from "../../utlis/useAxios";
import ViewHandler from './ViewHandler';

const Complete = () => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { signoutSilent } = useAuth();
    const location = useLocation();

    const { claim, setClaim } = useContext(ClaimContext);

    const query = new URLSearchParams(location.search);
    const claimUUIDfromURL = query.get('claim');
    const claimUUIDfromStorage = localStorage.getItem("claim");
    const [chatClaim, setChatClaim] = useState({});
    const [failed, setFailed] = useState(true);
    const [loading, setLoading] = useState(false);
    const [multiplePhotos, setMultiplePhotos] = useState(false);
    const [failedAttachments, setFailedAttachments] = useState([]);

    const attempted = useRef(false);
    const preview = useRef([]);
    const noPreviewDarkURL = useRef(null);
    const noPreviewLightURL = useRef(null);
    const stage = useRef(null);

    const axiosInstance = useAxios();

    useEffect(() => {
        if ((!claim?.claimUUID || !chatClaim?.claimUUID) && !attempted.current && (claimUUIDfromURL || claimUUIDfromStorage)) {
            fetchClaim();
            fetchConveration();
        }
        if (chatClaim?.claimUUID) {
            if (chatClaim?.attachments?.length > 0) {
                let count = 0;
                for (let i = 0; i < chatClaim?.attachments?.length; i++) {
                    const attachment = chatClaim?.attachments[i];
                    if (attachment?.documentType === "Foto des Schadens") {
                        count += 1;
                        if (count >= 2) {
                            setMultiplePhotos(true);
                        }
                    };
                };
            };
            if (preview.current?.length === 0 && chatClaim?.claimUUID) {
                fetchFallback();
                fetchAllAttachments();
            }
            setFailed(false);
        }
        //eslint-disable-next-line
    }, [chatClaim, preview.current])

    // useInterval(() => {
    //     if (stage.current !== "COMPLETED" || stage.current !== "FAILED") {
    //         fetchClaim();
    //     }
    // }, 2000);

    const createObjectUrl = (image, dark) => {
        fetch(image)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                if (dark) {
                    noPreviewDarkURL.current = url;
                } else {
                    noPreviewLightURL.current = url;
                }
            })
            .catch(err => console.error(err));
    };

    const fetchFallback = async () => {
        await createObjectUrl(noPreviewDark, true);
        await createObjectUrl(noPreviewLight, false);
    };

    const fetchClaim = async () => {
        setLoading(true);

        await axiosInstance.current({
            url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "GET"
        }).then((res) => {
            setClaim(res?.data ?? {});
            stage.current = res.data?.currentStage;
        }).catch(err => {
            setFailed(true);
            if (err.response.status === 401) {
                signoutSilent();
            }
        });
        attempted.current = true;
        setLoading(false);
    };

    const fetchConveration = async () => {
        setLoading(true);

        await axiosInstance.current({
            url: `/chat/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "GET"
        }).then((res) => {
            setChatClaim(res.data);
        }).catch(err => {
            setFailed(true);
            if (err.response.status === 401) {
                signoutSilent();
            }
        });
        attempted.current = true;
        setLoading(false);
    };

    const fetchAllAttachments = async () => {
        for (let i = 0; i < chatClaim?.attachments?.length; i++) {
            // console.log(claim?.attachments[i]?.attachmentUUID);
            await fetchPreview(chatClaim?.attachments[i]);
        };
    };

    const fetchPreview = async (attachment) => {
        setLoading(true);
        for (let i = 0; i < attachment?.src?.length; i++) {
            const current = attachment?.src[i] ?? undefined;
            await axiosInstance.current({
                url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}/${current}`,
                method: "GET",
                responseType: "blob"
            }).then((res) => {
                const previewObj = {
                    id: current,
                    data: URL.createObjectURL(res.data)
                }
                preview.current.push(previewObj);
            }).catch(() => setFailedAttachments([...failedAttachments, attachment?.attachmentUUID]));
        };
        setLoading(false);
    };

    //eslint-disable-next-line
    const handleUserResponse = async (response, id) => {
        setLoading(true);

        const body = {
            "questionUUID": id,
            "message": response
        };

        await axiosInstance.current({
            url: `/chat/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "POST",
            data: body
        }).then((res) => {
            setClaim(res.data);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    if (loading) {
        return (
            <Grid container sx={{ p: 2, height: belowmd ? "calc(100vh - 145px)" : "calc(100vh -75px)" }}>
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    };

    if ((!claimUUIDfromURL && !claimUUIDfromStorage) || failed) {
        return (
            <Container sx={{ height: belowmd && !belowsm ? "calc(100vh - 145px)" : "calc(100vh - 75px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography color={theme.palette.text.primary}>Keine Schadenmeldung vorhanden.</Typography>
            </Container>
        )
    };

    const getContent = (attachments) => {
        if (attachments) {
            let matching = [];
            for (let i = 0; i < attachments?.length; i++) {
                if (attachments[i]?.documentType === "Foto des Schadens") {
                    matching.push(attachments[i]);
                }
            };
            return matching;
        };
        return [];
    }

    const DesktopView = () => {

        const [openListClaim, setOpenListClaim] = useState(true);
        const [openListAttachments, setOpenListAttachments] = useState(true);

        const viewHandlerRef = useRef(null);

        useEffect(() => {
            openOverview();
        }, []);

        const handleListClaim = () => setOpenListClaim(!openListClaim);
        const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

        const getMatchingPhotos = () => {
            let matching = [];
            let blobOnly = [];
            for (let o = 0; o < chatClaim?.attachments?.length; o++) {
                if (chatClaim?.attachments[o]?.documentType === "Foto des Schadens") {
                    for (let i = 0; i < preview.current?.length; i++) {
                        if (chatClaim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                            const alreadyAdded = matching.filter(m => chatClaim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                            if (alreadyAdded?.length === 0) {
                                matching.push(preview.current[i]);
                            };
                        };
                    };
                };
            };
            if (matching?.length === 0) {
                const fallback = {
                    id: "fallback-preview",
                    data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
                };
                for (let i = 0; i < chatClaim?.attachments?.length; i++) {
                    if (chatClaim?.attachments[i]?.documentType === "Foto des Schadens") {
                        matching.push(fallback);
                    };
                };
            }
            for (let i = 0; i < matching?.length; i++) {
                blobOnly.push(matching[i].data);
            };
            return blobOnly;
        };

        const openOverview = () => viewHandlerRef.current.openOverview(chatClaim);
        const openCoverage = () => viewHandlerRef.current.openCoverage();
        const openMessage = () => viewHandlerRef.current.handleMessage(chatClaim);
        const openPhoto = () => {
            let sourceArray = [];
            // for (let i = 0; i < claim?.photos?.length; i++) {
            //     const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.photos[i]?.attachmentUUID}`;
            //     const name = claim?.photos[i]?.filename;
            //     const type = claim?.photos[i]?.contentType;
            //     const sourceObj = {
            //         "path": sourcePath,
            //         "filename": name,
            //         "contentType": type
            //     };
            //     sourceArray.push(sourceObj);
            // }
            if (chatClaim?.attachments?.length > 0) {
                for (let i = 0; i < chatClaim?.attachments?.length; i++) {
                    if (chatClaim?.attachments[i]?.documentType === "Foto des Schadens") {
                        const sourcePath = `/claims/${chatClaim?.claimUUID}/upload/${chatClaim?.attachments[i]?.attachmentUUID}`;
                        const name = chatClaim?.attachments[i]?.filename;
                        const type = chatClaim?.attachments[i]?.contentType;
                        const sourceObj = {
                            "path": sourcePath,
                            "filename": name,
                            "contentType": type
                        };
                        sourceArray.push(sourceObj);
                    };
                };
            };
            viewHandlerRef.current.handlePhoto(getContent(chatClaim?.attachments), getMatchingPhotos(), sourceArray);
        };

        const AttachementListItem = ({ displayName, attachment }) => {

            const getMatching = () => {
                let matching = [];
                for (let i = 0; i < preview.current?.length; i++) {
                    if ((preview.current[i]?.id === attachment?.attachmentUUID || attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0) && preview.current[i]?.data) {
                        matching.push(preview.current[i].data);
                    };
                };
                if (matching.length === 0) {
                    matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
                }
                return matching;
            };

            const openAttachment = () => {
                const type = attachment?.attachmentType;
                let sourceArray = [];
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${attachment?.attachmentUUID}`;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": attachment?.filename,
                    "contentType": attachment?.contentType
                };
                sourceArray.push(sourceObj);
                if (type === "Fnol") {
                    viewHandlerRef.current.handleFnol(attachment, getMatching(), sourceArray);
                };
                if (type === "PoliceReport") {
                    viewHandlerRef.current.handlePoliceReport(attachment, getMatching(), sourceArray);
                };
                if (type === "AccountingDocument" || type === "OtherDocument") {
                    viewHandlerRef.current.handleAttachment(attachment, getMatching(), sourceArray);
                };
            };

            if (attachment?.documentType === "Foto des Schadens") {
                return;
            };

            return (
                <ListItemButton onClick={() => { openAttachment() }} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                    <ListItemText primary={<Typography noWrap color="inherit">{displayName}</Typography>} />
                </ListItemButton>
            )
        };

        return (
            <Fragment>
                <Grid item xs={2} sx={{ height: belowmd && !belowsm ? "78vh" : "84vh", mt: 2, pb: 1.5, overflow: "auto" }}>
                    <Paper
                        elevation={2}
                        sx={{
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            height: "100%",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                        }}
                    >
                        <List disablePadding>
                            <ListItemButton onClick={handleListClaim} sx={{ pb: 0, pt: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">Meldung</Typography>} />
                                <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5, transition: 'transform 250ms', transform: openListClaim ? 'rotate(180deg)' : 'rotate(0deg)', }} />
                            </ListItemButton>
                            <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={openOverview} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">Übersicht</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCoverage} disabled={claim?.coverage === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">Deckung</Typography>} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleListAttachments} sx={{ py: 0, borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{claim?.attachments?.length > 0 ? "Anhänge" : "Anhang"}</Typography>} />
                                <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5, transition: 'transform 250ms', transform: openListAttachments ? 'rotate(180deg)' : 'rotate(0deg)', }} />
                            </ListItemButton>
                            <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                                <ListItemButton onClick={openMessage} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color="inherit">Meldung</Typography>} />
                                </ListItemButton>
                                <List component="div" disablePadding sx={{ height: "100%" }}>
                                    <ListItemButton onClick={openPhoto} sx={{ py: .25, pl: 4, borderRadius: "4px", display: getMatchingPhotos().length > 0 ? "flex" : "none" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{getMatchingPhotos()?.length > 1 ? "Schadenfotos" : "Schadenfoto"}</Typography>} />
                                    </ListItemButton>
                                    {chatClaim?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                                </List>
                            </Collapse>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={10} sx={{ height: "100%", pl: 2, pt: 2 }}>
                    <ViewHandler ref={viewHandlerRef} />
                </Grid>
            </Fragment>
        );
    };

    const MobileView = () => {

        const [openListClaim, setOpenListClaim] = useState(false);
        const [openListAttachments, setOpenListAttachments] = useState(false);

        const viewHandlerRef = useRef(null);

        useEffect(() => {
            openOverview();
        }, []);

        const handleListClaim = () => setOpenListClaim(!openListClaim);
        const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

        const getMatchingPhotos = () => {
            let matching = [];
            let blobOnly = [];
            for (let o = 0; o < chatClaim?.attachments?.length; o++) {
                if (chatClaim?.attachments[o]?.documentType === "Foto des Schadens") {
                    for (let i = 0; i < preview.current?.length; i++) {
                        if (chatClaim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                            const alreadyAdded = matching.filter(m => chatClaim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                            if (alreadyAdded?.length === 0) {
                                matching.push(preview.current[i]);
                            };
                        };
                    };
                };
            };
            if (matching?.length === 0) {
                const fallback = {
                    id: "fallback-preview",
                    data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
                };
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (chatClaim?.attachments[i]?.documentType === "Foto des Schadens") {
                        matching.push(fallback);
                    };
                };
            }
            for (let i = 0; i < matching?.length; i++) {
                blobOnly.push(matching[i].data);
            };
            return blobOnly;
        };

        const openOverview = () => {
            viewHandlerRef.current.openOverview(chatClaim);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openCoverage = () => {
            viewHandlerRef.current.openCoverage();
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openMessage = () => {
            viewHandlerRef.current.handleMessage(chatClaim);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openPhoto = () => {
            let sourceArray = [];
            if (chatClaim?.attachments?.length > 0) {
                for (let i = 0; i < chatClaim?.attachments?.length; i++) {
                    if (chatClaim?.attachments[i]?.documentType === "Foto des Schadens") {
                        const sourcePath = `/claims/${chatClaim?.claimUUID}/upload/${chatClaim?.attachments[i]?.attachmentUUID}`;
                        const name = chatClaim?.attachments[i]?.filename;
                        const type = chatClaim?.attachments[i]?.contentType;
                        const sourceObj = {
                            "path": sourcePath,
                            "filename": name,
                            "contentType": type
                        };
                        sourceArray.push(sourceObj);
                    };
                };
            };
            viewHandlerRef.current.handlePhoto(getContent(chatClaim?.attachments), getMatchingPhotos(), sourceArray);
            setOpenListAttachments(false);
        };

        const AttachementListItem = ({ displayName, attachment }) => {

            const getMatching = () => {
                let matching = [];
                for (let i = 0; i < preview.current?.length; i++) {
                    if ((preview.current[i]?.id === attachment?.attachmentUUID || (attachment.src && attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0)) && preview.current[i]?.data) {
                        matching.push(preview.current[i].data);
                    };
                };
                if (matching.length === 0) {
                    matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
                }
                return matching;
            };

            const openAttachment = () => {
                setOpenListClaim(false);
                setOpenListAttachments(false);
                const type = attachment?.attachmentType;
                let sourceArray = [];
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${attachment?.attachmentUUID}`;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": attachment?.filename,
                    "contentType": attachment?.contentType
                };
                sourceArray.push(sourceObj);
                if (type === "Fnol") {
                    viewHandlerRef.current.handleFnol(attachment, getMatching(), sourceArray);
                };
                if (type === "PoliceReport") {
                    viewHandlerRef.current.handlePoliceReport(attachment, getMatching(), sourceArray);
                };
                if (type === "AccountingDocument" || type === "OtherDocument") {
                    viewHandlerRef.current.handleAttachment(attachment, getMatching(), sourceArray);
                };
            };

            if (attachment?.documentType === "Foto des Schadens") {
                return;
            }

            return (
                <ListItemButton onClick={() => { openAttachment() }} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{displayName}</Typography>} />
                </ListItemButton>
            )
        };

        return (
            <Fragment>
                <Paper
                    elevation={2}
                    sx={{
                        mt: 2,
                        width: "100%",
                        color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                    }}
                >
                    <List disablePadding>
                        <ListItemButton onClick={handleListClaim} sx={{ p: 0, px: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                            <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Meldung</Typography>} />
                            <IconButton sx={{ transform: openListClaim ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                            </IconButton>
                        </ListItemButton>
                        <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton onClick={openOverview} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Übersicht</Typography>} />
                                </ListItemButton>
                                <ListItemButton onClick={openCoverage} disabled={claim?.coverage === null} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Deckung</Typography>} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleListAttachments} sx={{ p: 0, px: 1, borderBottom: openListAttachments ? 1 : 0, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                            <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Anhänge</Typography>} />
                            <IconButton sx={{ transform: openListAttachments ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                            </IconButton>
                        </ListItemButton>
                        <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ height: "100%" }}>
                                <ListItemButton onClick={openMessage} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Meldung</Typography>} />
                                </ListItemButton>
                                <ListItemButton onClick={openPhoto} sx={{ py: .25, pl: 4, borderRadius: "6px", display: getMatchingPhotos().length > 0 ? "flex" : "none" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{multiplePhotos ? "Schadenfotos" : "Schadenfoto"}</Typography>} />
                                </ListItemButton>
                                {chatClaim?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                            </List>
                        </Collapse>
                    </List>
                </Paper>
                <ViewHandler ref={viewHandlerRef} />
            </Fragment>
        );
    };

    return (
        <Fragment>
            {/* <Box
                sx={{
                    mt: 1,
                    py: .5,
                    width: "100vw",
                    display: belowsm ? "none" : "flex",
                    justifyContent: "center",
                    bgcolor: theme.palette.mode === "dark" ? "rgba(139, 0, 0, .15)" : "#FFF3F3"
                }}
            >
                <WarningDiamondIcon />
                <Typography color={theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.complete.primary} sx={{ mx: 1 }}>{t("confidence")}</Typography>
                <Typography color={theme.palette.mode === "dark" ? theme.palette.text.secondary : theme.palette.complete.secondary}>{t("reliable", { percentage: chatClaim?.riskConfidence ? chatClaim.riskConfidence * 100 : 0 })}</Typography>
            </Box> */}
            <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)" }}>
                {belowsm ? <MobileView /> : <DesktopView />}
            </Grid>
        </Fragment>
    );

};

export default Complete;