import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Container, Grid, IconButton, Link, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/de';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomActionBar from '../utlis/CustomActionBar';
import { useAxios } from "../utlis/useAxios";
import ErrorDialog from './Dialog/ErrorDialog';
import HelpDialog from './Dialog/HelpDialog';
import InfoDialog from './Dialog/InfoDialog';
import NotImplementedDialog from './Dialog/NotImplementedDialog';

const Report = ({ reportRef }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();

    const [switchView, setSwitchView] = useState(false);
    const [claimUUID, setClaimUUID] = useState("");

    const axiosInstance = useAxios();

    const View1 = () => {
        const startDate = moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);

        useEffect(() => {
            setEmail(reportRef.current?.email ?? "");
            setDate(reportRef.current?.date ?? startDate);
            setContent(reportRef.current?.content ?? "");
            setAttachments(reportRef.current?.attachments ?? []);
            setContracts(reportRef.current?.contracts ?? []);
            setLoading(reportRef.current?.loading ?? false);
            //eslint-disable-next-line
        }, []);

        const [content, setContent] = useState("");
        const [email, setEmail] = useState("");
        const [date, setDate] = useState(startDate);
        const [openDate, setOpenDate] = useState(false);
        const [emailError, setEmailError] = useState(false);
        const [textError, setTextError] = useState(false);
        const [displayInfo, setDisplayInfo] = useState(false);
        const [attachments, setAttachments] = useState([]);
        const [contracts, setContracts] = useState([]);
        const [loading, setLoading] = useState(false);
        const [displayHelp, setDisplayHelp] = useState(false);

        const infoRef = useRef(null);
        const helpRef = useRef(null);
        const errorRef = useRef(null);
        const fileInputRef = useRef(null);
        const photoInputRef = useRef(null);
        const contractInputRef = useRef()
        const displayHelpRef = useRef(null);

        const handleEmail = (event) => {
            if (validate(event.target.value) || event.target.value === "") {
                setEmailError(false);
            } else {
                setEmailError(true);
            }
            setEmail(event.target.value);
            reportRef.current = {
                ...reportRef.current,
                "email": event.target.value
            };
        };

        const handleChange = (event) => {
            setContent(event.target.value);
            setTextError(false);
            reportRef.current = {
                ...reportRef.current,
                "content": event.target.value
            };
        };

        const handleDate = newValue => {
            setDate(newValue);
            reportRef.current = {
                ...reportRef.current,
                "date": newValue
            };
        };

        const handleDateOpen = () => setOpenDate(!openDate);

        const handleDisplayInfo = () => setDisplayInfo(!displayInfo);

        const handleDisplayHelpDialog = () => helpRef.current.open();

        const handleDislayInfoDialog = () => infoRef.current.open();

        const handleFiles = () => fileInputRef.current.click();

        const handlePhotos = () => photoInputRef.current.click();

        const handleContracts = () => contractInputRef.current.click();

        const onFileChange = (event) => {
            // console.log(event.target.files)
            if (event.target?.files?.length > 0) {
                const items = [...attachments];
                items.push(...event.target?.files);
                setAttachments(items);
                reportRef.current = {
                    ...reportRef.current,
                    "attachments": [...items]
                };
            };
        };

        const onContractChange = (event) => {
            if (event.target?.files?.length > 0) {
                const items = [...contracts];
                items.push(...event.target?.files);
                setContracts(items);
                reportRef.current = {
                    ...reportRef.current,
                    "contracts": [...items]
                };
            };
        };

        const handleDisplayHelpIn = () => {
            displayHelpRef.current = setTimeout(() => setDisplayHelp(true), 250);
        };

        const handleDisplayHelpOut = () => {
            clearTimeout(displayHelpRef.current);
            setDisplayHelp(false);
        };

        const onFileDelete = (i) => {
            const items = [...attachments];
            items.splice(i, 1);
            setAttachments(items);
            reportRef.current = {
                ...reportRef.current,
                "attachments": [...items]
            };
        }

        const onContractDelete = (i) => {
            const items = [...contracts];
            items.splice(i, 1);
            setContracts(items);
            reportRef.current = {
                ...reportRef.current,
                "contracts": [...items]
            };
        }

        const validate = (email) => {
            const regex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return regex.test(email);
        };

        const submit = async () => {
            setLoading(true);
            reportRef.current = {
                ...reportRef.current,
                "loading": true
            };

            if (email === "") {
                setEmailError(true);
                setLoading(false);
                reportRef.current = {
                    ...reportRef.current,
                    "loading": false
                };
                return null;
            };

            if (content === "") {
                setTextError(true);
                setLoading(false);
                reportRef.current = {
                    ...reportRef.current,
                    "loading": false
                };
                return null;
            };

            const formData = new FormData();

            const json = {
                "sender": email,
                "sentAt": date.toISOString(),
                "initialMessage": content,
                "conversations": [],
                "version": 1
            }
            const jsonBlob = new Blob([JSON.stringify(json)], { type: 'application/json' });
            formData.append('conversations', jsonBlob, 'conversations.json');
            if (attachments?.length > 0) {
                for (const file of attachments) {
                    formData.append('file', file);
                }
            };
            if (contracts?.length > 0) {
                for (const contract of contracts) {
                    formData.append('contract', contract);
                }
            };

            await axiosInstance.current({
                url: "/chat",
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }).then((res) => {
                setClaimUUID(res.data?.claimUUID);
                localStorage.setItem("claim", res.data?.claimUUID);
                reportRef.current = {
                    "email": "",
                    "date": moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0),
                    "content": "",
                    "attachments": [],
                    "contracts": [],
                    "loading": false
                };
                setSwitchView(true);
            }).catch(err => errorRef.current.open(err.response.status));

            setLoading(false);
            reportRef.current = {
                ...reportRef.current,
                "loading": false
            };
        };

        const bull = (
            <Box
                component="span"
                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(1.3)', pb: 1, mr: 1 }}
            >
                •
            </Box>
        );

        return (
            <Grid container sx={{ pt: belowsm ? 2 : 2, px: 2 }}>
                <Grid item xs={displayInfo && !belowmd ? 8 : 12}>
                    <Grid container justifyContent={belowsm ? "center" : "flex-start"} sx={{ pb: 1, borderBottom: 1, borderBottomColor: "divider" }}>
                        <Typography variant={belowsm ? "h5" : "h3"} fontWeight={500} color={theme.palette.text.primary}>Schadenmeldung</Typography>
                        <IconButton onClick={handleDisplayInfo} sx={{ ml: 1, display: belowmd ? "none" : "flex" }}>
                            <InfoOutlinedIcon sx={{ fontSize: "42px", color: theme.palette.text.primary }} />
                        </IconButton>
                    </Grid>
                    <Grid container sx={{ mt: 2, mb: belowmd ? 1 : 0 }}>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ textAlign: belowsm ? "center" : "start", flexGrow: belowsm ? 0 : 1, pl: 1, pb: belowsm ? 0 : 2 }}>Bitte erfassen Sie im Textfeld Ihre Schadenmeldung.</Typography>
                        <Typography onClick={handleDislayInfoDialog} variant="body1" color={theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main} sx={{ textAlign: "center", display: belowsm ? "block" : "none", flexGrow: 1, textDecoration: "underline", cursor: "pointer" }}> Welche Daten benötigen wir von Ihnen</Typography>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={6} sx={{ mb: emailError ? 1 : -0.5, mt: belowsm ? 1 : 0 }}>
                            <TextField
                                label="E-Mail Adresse"
                                value={email}
                                onChange={handleEmail}
                                fullWidth
                                required
                                error={emailError}
                                helperText={emailError ? "Sie müssen eine gültige E-Mail Adresse angeben." : " "}
                                InputLabelProps={{ shrink: true }}
                                disabled={loading}
                                sx={{
                                    "& label.Mui-focused": {
                                        color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&:hover fieldset": {
                                            borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ mb: 1, pl: belowsm ? 0 : 2 }}>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                                <DatePicker
                                    label="Sendedatum"
                                    format="DD.MM.YYYY"
                                    value={date}
                                    onChange={handleDate}
                                    open={openDate}
                                    onClose={handleDateOpen}
                                    disableFuture
                                    disabled={loading}
                                    slots={{
                                        actionBar: CustomActionBar,
                                    }}
                                    slotProps={{
                                        textField: {
                                            onClick: handleDateOpen,
                                            InputLabelProps: {
                                                shrink: true
                                            },
                                            inputProps: {
                                                readOnly: true
                                            },
                                            fullWidth: true,
                                            required: true,
                                            helperText: belowsm ? "" : displayHelp ? 'In Emails wird oft ein relatives Datum  wie "gestern" oder "letzte Woche" verwendet. Das Sendedatum wird herangezogen, um solche Angaben in ein absolutes Datum umzuwandeln.' : "Eingabehilfe anzeigen",
                                            FormHelperTextProps: {
                                                onMouseOver: () => handleDisplayHelpIn(),
                                                onMouseOut: () => handleDisplayHelpOut()
                                            },
                                            sx: {
                                                "& label.Mui-focused": {
                                                    color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    "&:hover fieldset": {
                                                        borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                    }
                                                }
                                            }
                                        },
                                        day: {
                                            sx: {
                                                "&.Mui-selected": {
                                                    bgcolor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                                },
                                            }
                                        },
                                        openPickerIcon: {
                                            sx: { display: "none" }
                                        },
                                        toolbar: {
                                            hidden: true
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            <Button
                                onClick={handleDisplayHelpDialog}
                                size="small"
                                fullWidth
                                sx={{
                                    display: belowsm ? "inline-flex" : "none",
                                    py: 0,
                                    textTransform: "none",
                                    justifyContent: "flex-start",
                                    color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.6)",
                                    "&:hover": {
                                        color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.6)"
                                    }
                                }}
                            >
                                <Typography fontSize={12} >Eingabehilfe anzeigen</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <TextField
                        label="Meldungstext"
                        value={content}
                        onChange={handleChange}
                        multiline
                        fullWidth
                        required
                        error={textError}
                        helperText={textError ? "Fehlerhafter Corpus." : " "}
                        placeholder="Bitte hier Text eingeben."
                        minRows={belowsm ? 5 : 20}
                        InputLabelProps={{ shrink: true }}
                        disabled={loading}
                        sx={{
                            mb: textError ? 1 : -0.5,
                            "& label.Mui-focused": {
                                color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                            },
                            "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                    borderColor: theme.palette.mode === "dark" ? theme.palette.action.disabled : theme.palette.action.active,
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
                                }
                            }
                        }}
                    />
                    <Grid container columnGap={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Typography variant="body1" color={theme.palette.text.primary} sx={{ display: attachments?.length > 0 || contracts.length > 0 ? "flex" : "none", mt: .5, mb: 1 }}>{(attachments?.length === 1 || contracts.length === 1) && attachments?.length + contracts.length <= 1 ? "Anhang:" : "Anhänge:"}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container columnGap={1}>
                                {attachments?.map((f, i) => { return <Chip key={`file-${i}`} label={f?.name || "Fehler mit Datei"} variant={theme.palette.mode === "dark" ? "outlined" : "filled"} onClick={belowsm ? () => onFileDelete(i) : null} onDelete={() => onFileDelete(i)} deleteIcon={<DeleteOutlineIcon />} disabled={loading} sx={{ mb: 1, borderRadius: "4px", maxWidth: displayInfo ? "61.5vw" : "92vw", "& .MuiChip-label": { textOverflow: "ellipsis" } }} /> })}
                                {contracts?.map((f, i) => { return <Chip key={`contract-${i}`} label={f?.name || "Fehler mit Datei"} variant={theme.palette.mode === "dark" ? "outlined" : "filled"} onClick={belowsm ? () => onContractDelete(i) : null} onDelete={() => onContractDelete(i)} deleteIcon={<DeleteOutlineIcon />} disabled={loading} sx={{ mb: 1, borderRadius: "4px", maxWidth: displayInfo ? "61.5vw" : "92vw", "& .MuiChip-label": { textOverflow: "ellipsis" } }} /> })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={belowsm ? 1 : 2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={3}>
                            <Button
                                onClick={handlePhotos}
                                disableElevation
                                variant="outlined"
                                size="large"
                                startIcon={<AddAPhotoOutlinedIcon />}
                                fullWidth
                                disabled={loading}
                                sx={{
                                    height: "42.25px",
                                    textTransform: "none",
                                    border: 1.5,
                                    borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    "&:hover": {
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                    }
                                }}>
                                <input
                                    accept="image/png, image/jpeg, image/jpg"
                                    type="file"
                                    multiple
                                    onChange={onFileChange}
                                    onClick={event => event.target.value = null}
                                    ref={photoInputRef}
                                    hidden
                                />
                                <Typography fontSize="15px" fontWeight={500} noWrap>{(belowmd && !belowsm) || displayInfo ? "Fotos" : "Fotos hochladen"}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button
                                onClick={handleFiles}
                                disableElevation
                                variant="outlined"
                                size="large"
                                startIcon={<UploadFileIcon />}
                                fullWidth
                                disabled={loading}
                                sx={{
                                    mt: belowsm ? 1 : 0,
                                    height: "42.25px",
                                    textTransform: "none",
                                    border: 1.5,
                                    borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    "&:hover": {
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                    }
                                }}>
                                <input
                                    accept=".pdf"
                                    type="file"
                                    multiple
                                    onChange={onFileChange}
                                    onClick={event => event.target.value = null}
                                    ref={fileInputRef}
                                    hidden
                                />
                                <Typography fontSize="15px" fontWeight={500} noWrap>{(belowmd && !belowsm) || displayInfo ? "Dokumente" : "Dokumente hochladen"}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button
                                onClick={handleContracts}
                                disableElevation
                                variant="outlined"
                                size="large"
                                startIcon={<DescriptionOutlinedIcon />}
                                fullWidth
                                disabled={loading}
                                sx={{
                                    mt: belowsm ? 1 : 0,
                                    height: "42.25px",
                                    textTransform: "none",
                                    border: 1.5,
                                    borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    color: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    "&:hover": {
                                        border: 1.5,
                                        borderColor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                        color: theme.palette.mode === "dark" ? "primary.light" : "primary.main"
                                    }
                                }}>
                                <input
                                    accept=".pdf"
                                    type="file"
                                    multiple
                                    onChange={onContractChange}
                                    onClick={event => event.target.value = null}
                                    ref={contractInputRef}
                                    hidden
                                />
                                <Typography fontSize="15px" fontWeight={500} noWrap>{(belowmd && !belowsm) || displayInfo ? "Vertragsdaten" : "Vertragsdaten hochladen"}</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <LoadingButton
                                onClick={submit}
                                disableElevation
                                variant="contained"
                                size="large"
                                endIcon={<ArrowCircleRightOutlinedIcon />}
                                fullWidth
                                loading={loading}
                                sx={{
                                    mt: belowsm ? 1 : 0,
                                    textTransform: "none",
                                    fontWeight: 600,
                                    bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                                    "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                                }}>Weiter</LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} sx={{ display: displayInfo && !belowmd ? "flex" : "none", pl: 2, pt: 8, pb: 10.5 }}>
                    <Box sx={{ border: 1, borderColor: "divider", height: "100%", width: "100%", borderRadius: "6px" }}>
                        <Typography variant="h5" fontWeight={500} color={theme.palette.text.primary} sx={{ px: 2, pt: 2, textAlign: "center" }}>Welche Daten benötigen wir  von Ihnen:</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ mt: 3, ml: 2, lineHeight: "normal" }}>{bull}Schadendatum</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Uhrzeit</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Schadenort</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Schadenhergang</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Was ist beschädigt</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Kennzeichnen bei KFZ</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Versicherungsscheinnummer oder Name und Adresse des Versicherungsnehmer</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Optional können Sie Bilder zum Schaden hochladen</Typography>
                        <Typography variant="body1" color={theme.palette.text.primary} sx={{ ml: 2, mt: .5, lineHeight: "normal" }}>{bull}Optional können Sie Dokumente zum Schaden (Kostenvoranschlag, Polizeibericht, Rechnung usw.) hochladen</Typography>
                    </Box>
                </Grid>
                <InfoDialog ref={infoRef} />
                <HelpDialog ref={helpRef} />
                <ErrorDialog ref={errorRef} />
            </Grid>
        );
    };

    const View2 = () => {

        const notImplementedRef = useRef(null);

        const handleDislayNotImplementedDialog = () => notImplementedRef.current.open();

        return (
            <Container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: belowmd && !belowsm ? "calc(100vh - 130px)" : "calc(100vh - 76px)" }}>
                <Typography variant="h6" color={theme.palette.text.primary} sx={{ textAlign: "center", mb: 2 }}>Vielen Dank, Ihre Meldung wurde verarbeitet.</Typography>
                <Grid container flexDirection="row">
                    <Grid item xs={12} sm={6} sx={{ px: 1 }}>
                        <Box sx={{ border: 1, borderColor: "text.secondary", borderRadius: "4px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, pl: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px", width: "100%" }}>Schadenmelder</Typography>
                            <Typography variant="body1" color={theme.palette.text.primary} sx={{ textAlign: "center", mt: 1, px: 1 }}>Sie können Ihre Schadenmeldung für eine beschleunigte Abwicklung online abschließen.</Typography>
                            <Typography variant="body1" color={theme.palette.text.primary} sx={{ textAlign: "center", px: 1 }}>Klicken Sie hierfür auf diesen Link:</Typography>
                            <Link onClick={handleDislayNotImplementedDialog} sx={{ textAlign: "center", my: 2, cursor: "pointer", color: theme.palette.mode === "dark" ? "primary.light" : "primary.main", px: 1 }}>Schadenmeldung online vervollständigen</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ px: 1, mt: belowsm ? 2 : 0 }}>
                        <Box sx={{ border: 1, borderColor: "text.secondary", borderRadius: "4px", display: "flex", flexDirection: "column", alignItems: "center", height: belowsm ? "198px" : "100%" }}>
                            <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.text.primary} sx={{ py: .25, px: 1, bgcolor: "divider", borderTopLeftRadius: "4px", borderTopRightRadius: "4px", width: "100%" }}>Sachbearbeiter</Typography>
                            <Typography variant="body1" color={theme.palette.text.primary} sx={{ textAlign: "center", mt: 1, px: 1 }}>Hier geht es zur Übersicht der Schadenmeldung</Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Link onClick={() => { navigate(`/complete?claim=${claimUUID}`) }} sx={{ textAlign: "center", mb: 2, cursor: "pointer", color: theme.palette.mode === "dark" ? "primary.light" : "primary.main", px: 1 }}>Schadenmeldung überprüfen</Link>
                        </Box>
                    </Grid>
                </Grid>
                <NotImplementedDialog ref={notImplementedRef} />
            </Container>
        );
    };

    return switchView ? <View2 /> : <View1 />;
};

export default Report;