import { useTheme } from "@emotion/react";
import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import WidgetTextField from "../WidgetTextField";

const Message = ({ data }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { t } = useTranslation();

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ mt: belowsm ? 2 : 0, pb: 1.5, height: belowsm ? "calc(100vh - 327px)" : "auto", overflow: "auto" }}>
            <Grid item xs={12}>
                <Paper
                    elevation={2}
                    sx={{
                        height: belowsm ? "auto" : "100%",
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Grid container
                        sx={{
                            p: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                color: theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: 2
                            }}
                        >
                            {t("message")}
                        </Typography>
                    </Grid>
                    <Box sx={{ py: 1 }}>
                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: data?.hint ? "flex" : "none", px: 1 }}>{`${data?.hint}`}</Typography>
                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: data?.hint ? "none" : "flex", px: 1 }}>{`${data?.lossDate ? `Am ${data?.lossDate} ` : ""}${data?.sender ? `${data?.sender}` : ""} ${!data?.lossDate && !data?.sender ? "Hat" : "hat"} folgende Nachricht übermittelt:`}</Typography>
                        <WidgetTextField
                            color="widget"
                            label={t("messageText")}
                            value={data?.message ?? t("noMessageText")}
                            variant="standard"
                            fullWidth
                            focused
                            multiline
                            InputLabelProps={{
                                shrink: true,
                                sx: { px: 1.5 }
                            }}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: false,
                                sx: {
                                    px: 1.5,
                                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                                }
                            }}
                            sx={{ my: 1 }}
                        />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Message;