import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const Photo = ({ data, photoPreview }) => {
    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    const [photos, setPhotos] = useState([]);
    const [preview, setPreview] = useState([]);

    useEffect(() => {
        if (data) {
            setPhotos(data);
            // console.log("Photos:", data);
        }
        if (photoPreview) {
            setPreview(photoPreview);
            // console.log("preview: ", photoPreview);
        }
        //eslint-disable-next-line
    }, [data]);

    const checkInput = (input, notApplicable) => {
        if (input && input?.length > 0) {
            return input;
        };
        if (notApplicable) {
            return "Nicht anwendbar";
        };
        return "Nicht gefunden";
    };

    const Preview = ({ photo, index }) => {

        return (
            <Paper
                elevation={2}
                sx={{
                    width: "100%",
                    pb: 1,
                    mb: 1.5,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        p: 1,
                        borderBottom: 1,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            pr: 2
                        }}
                    >
                        {photo?.filename}
                    </Typography>
                </Grid>
                <Box sx={{ p: 1 }}>
                    <Grid container sx={{ justifyContent: belowsm ? "center" : "flex-start" }}>
                        <Grid item xs={12} sm={3} sx={{ maxWidth: "100%", display: "flex", justifyContent: "center" }}>
                            <img alt={`preview-${index}`} src={preview[index]} style={{ borderRadius: "4px" }} />
                        </Grid>
                        <Grid item xs={12} sm={9} zeroMinWidth sx={{ pl: 2 }}>
                            <Grid container sx={{ mb: 1, mt: belowsm ? 2 : 0 }}>
                                <Grid item>
                                    <Avatar sx={{ height: 20, width: 20, bgcolor: photo?.related ? "success.main" : "error.main", mr: 2, mt: .2 }}>
                                        {photo?.related ? <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} /> : <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />}
                                    </Avatar>
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{photo?.related ? "Foto passt zur Schadenbeschreibung" : "Foto passt nicht zur Schadenbeschreibung"}</Typography>
                                </Grid>
                            </Grid>
                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{photo?.description ?? ""}</Typography>
                            <Grid container columnGap={belowsm ? 0 : 2} sx={{ mt: 1 }}>
                                <Grid item>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{`${t("explanation")}:`}</Typography>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(photo?.documentTypeReason)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.documentTypeReason)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pl: 2, pr: 1, mt: 1, justifyContent: belowsm ? "flex-start" : "flex-end", display: photo.hasCar ? "flex" : "none" }}>
                            <Grid item xs={12} sm={5} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                                <Typography variant="h6" fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{t("car")}</Typography>
                                <Grid container columnGap={belowmd ? 0 : 2}>
                                    <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carLicensePlate")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carLicensePlate, true)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carVin")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carVin, true)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carYear")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carYear, true)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carLicensePlate, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carVin, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carYear, true)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} zeroMinWidth sx={{ pr: belowmd ? 0 : 1, mt: belowsm ? 0 : "32px" }}>
                                <Grid container columnGap={belowmd ? 0 : 2}>
                                    <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>{`${t("carMake")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carMake, true)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>{`${t("carModel")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carModel, true)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>{`${t("carColor")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carColor, true)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carMake, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carModel, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carColor, true)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} zeroMinWidth sx={{ mt: belowmd ? 0 : 1 }}>
                                <Grid container columnGap={belowmd ? 0 : 2}>
                                    <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carDamage")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carDamage, true)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carDamageLocation")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carDamageLocation, true)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carLocation")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carLocation, true)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carMileage")}:`}</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(photo?.carMileage, true)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carDamage, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carDamageLocation, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carLocation, true)}</Typography>
                                        <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(photo?.carMileage, true)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box >
            </Paper>
        );
    };

    if (photos.length === 0) {
        return (
            <Box sx={{ height: belowsm ? "calc(100vh - 277px)" : "auto", width: belowsm ? "90vw" : "80vw", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center", mt: belowsm ? 0 : "35vh" }}>
                <Typography color={theme.palette.text.primary}>{t("photoDisplayError")}</Typography>
            </Box>
        )
    }

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ height: belowsm ? "calc(100vh - 327px)" : "auto", overflow: "auto", justifyContent: "center", alignItems: "center" }}>
            {photos?.map((p, i) => { return <Preview key={i} photo={p} index={i} /> })}
        </Grid>
    );
};

export default Photo;