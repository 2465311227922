import { Backdrop, Box, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../utlis/useAxios";
import ClaimDialog from "./Dialog/ClaimDialog";

const Claims = ({ reportRef }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const { signoutSilent } = useAuth();

    const navigate = useNavigate();

    const [claims, setClaims] = useState([]);
    const [rows, setRows] = useState(20);
    const [activePage, setActivePage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);

    const pageable = useRef({
        "size": 20,
        "currentPage": 0,
        "empty": true,
        "totalElements": 0,
        "totalPages": 0
    });
    const attempted = useRef(false);
    const claimRef = useRef(null);

    const axiosInstance = useAxios();

    useEffect(() => {
        if (reportRef.current) {
            reportRef.current = {
                "email": "",
                "date": moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0),
                "content": "",
                "attachments": [],
                "contracts": [],
                "loading": false
            };
        }
        if (!attempted.current && claims?.length === 0) {
            fetchClaims();
        };
        //eslint-disable-next-line
    }, [claims, attempted.current]);

    const fetchClaims = async (size, page) => {
        if (claims?.filter(c => c.page === page)?.length > 0) {
            setActivePage(page || 0);
            pageable.current({
                ...pageable.current,
                "currentPage": page ? page : 0
            });
            return;
        };
        setLoading(true);

        await axiosInstance.current({
            url: `/claims${size ? `?size=${size}` : ""}${page && !size ? `?page=${page}` : page && size ? `&page=${page}` : ""}`,
            method: "GET"
        }).then((res) => {
            const newClaims = [
                ...claims,
                {
                    "page": page ? page : 0,
                    "content": res.data?.content
                }];
            setClaims(newClaims);
            setActivePage(page || 0);
            pageable.current = ({
                ...pageable.current,
                "size": res.data?.size,
                "currentPage": page ? page : 0,
                "empty": res.data?.empty,
                "totalElements": res.data?.totalElements,
                "totalPages": res.data?.totalPages
            });
        }).catch(() => err => {
            setFailed(true);
            if (err.response.status === 401) {
                signoutSilent();
            }
        });
        attempted.current = true;
        setLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > activePage && claims?.filter(c => c.page === newPage)?.length === 0) {
            fetchClaims(pageable.current.size, newPage);
        } else {
            setActivePage(newPage);
        };
    };

    const handleChangeRowsPerPage = (event) => {
        setRows(+event.target.value);
        pageable.current = ({
            ...pageable.current,
            "size": +event.target.value,
            "currentPage": 0
        })
        fetchClaims(+event.target.value);
    };

    const Claim = ({ data }) => {

        const handleClick = () => {
            if (belowsm) {
                claimRef.current.open(data);
            } else {
                localStorage.setItem("claim", data?.claimUUID);
                navigate(`/complete?claim=${data?.claimUUID}`)
            }
        };

        return (
            <TableRow onClick={handleClick} sx={{ cursor: "pointer", color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                <TableCell align="left" sx={{ color: "inherit", borderBottomColor: "inherit" }}>{moment(data?.sentAt).format("DD.MM.YYYY") ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left" sx={{ color: "inherit", borderBottomColor: "inherit" }}>{data?.sender ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left" sx={{ color: "inherit", borderBottomColor: "inherit" }}>{data?.policy ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left" sx={{ color: "inherit", borderBottomColor: "inherit" }}>{data?.insurance ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left" sx={{ color: "inherit", borderBottomColor: "inherit" }}>{data?.risk ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left" sx={{ color: "inherit", borderBottomColor: "inherit" }}>{data?.cause ?? "Nicht vorhanden"}</TableCell>
                <TableCell align="left" sx={{ color: "inherit", borderBottomColor: "inherit" }}>{data?.lossDescription ?? "Nicht vorhanden"}</TableCell>
            </TableRow>
        );
    };

    if (loading) {
        return (
            <Grid container sx={{ p: 2, height: belowmd ? "calc(100vh - 130px)" : "calc(100vh - 64px)" }}>
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    };

    return (
        <Grid container sx={{ p: 2, height: belowmd && !belowsm ? "calc(100vh - 130px)" : "calc(100vh - 76px)" }}>
            <Grid item xs={12}>
                <Paper
                    elevation={2}
                    sx={{
                        mt: 1,
                        width: "100%",
                        height: belowmd && !belowsm ? "80vh" : "87vh",
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            pt: belowsm ? 1.75 : 1.5,
                            px: 1,
                            pb: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        Meine Schäden
                    </Typography>
                    <Box sx={{ pb: 1 }}>
                        {failed ?
                            <Box sx={{ width: "100%", height: "75vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography color={theme.palette.mode === "dark" ? theme.palette.text.primary : theme.palette.widget.contrastText} sx={{ textAlign: "center" }}>Fehler mit der Anzeige der existierenden Schäden.</Typography>
                            </Box>
                            :
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: belowmd && !belowsm ? "74vh" : "81vh",
                                    flexGrow: 1
                                }}
                            >
                                <TableContainer sx={{ maxHeight: "75vh" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                                <TableCell align="left" sx={{ width: "125px", bgcolor: "transparent", borderBottomColor: "inherit", color: "inherit", fontWeight: "bold" }}>Gesendet am</TableCell>
                                                <TableCell align="left" sx={{ bgcolor: "transparent", borderBottomColor: "inherit", color: "inherit", fontWeight: "bold" }}>Melder</TableCell>
                                                <TableCell align="left" sx={{ width: "130px", bgcolor: "transparent", borderBottomColor: "inherit", color: "inherit", fontWeight: "bold" }}>Polizze</TableCell>
                                                <TableCell align="left" sx={{ bgcolor: "transparent", borderBottomColor: "inherit", color: "inherit", fontWeight: "bold" }}>Versicherung</TableCell>
                                                <TableCell align="left" sx={{ bgcolor: "transparent", borderBottomColor: "inherit", color: "inherit", fontWeight: "bold" }}>Risiko</TableCell>
                                                <TableCell align="left" sx={{ bgcolor: "transparent", borderBottomColor: "inherit", color: "inherit", fontWeight: "bold" }}>Ursache</TableCell>
                                                <TableCell align="left" sx={{ bgcolor: "transparent", borderBottomColor: "inherit", color: "inherit", fontWeight: "bold" }}>Schadenbeschreibung</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {claims?.filter(c => c.page === activePage)[0]?.content?.map((c, i) => { return <Claim key={i} data={c} /> })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ flexGrow: 1 }} />
                                <TablePagination
                                    // rowsPerPageOptions={[20, 100]}
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={pageable.current.totalElements}
                                    rowsPerPage={rows}
                                    page={activePage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        }
                    </Box>
                    <ClaimDialog ref={claimRef} />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Claims;