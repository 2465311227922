import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { deDE } from '@mui/material/locale';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Claims from './components/Claims';
import Complete from './components/Complete/Complete';
import Login from './components/Login';
import Nav from './components/Nav';
import Report from './components/Report';
import ClaimProvider from './utlis/ClaimContext';
import PrivateRoute from './utlis/PrivateRoute';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: "#335F9A"
      },
      // background: {
      //   default: "#f6f6f6"
      // },
      profile: {
        main: "#2C2C2C",
        contrastText: "#F5F5F5"
      },
      signout: {
        main: "#E3E3E3",
        border: "#767676",
        contrastText: "#1E1E1E"
      },
      widget: {
        main: "#335F9A",
        paper: "#F8F6F4",
        divider: prefersDarkMode ? "rgba(255, 255, 255, 0.08)" : "#f0e6dd",
        title: "#3E3C3C",
        contrastText: "#615F5E",
        triangleIcon: "#474444",
        icon: prefersDarkMode ? "rgba(255, 255, 255, 0.70)" : "#4B4F56"
      },
      complete: {
        border: "#FFD9D9",
        primary: "#3E3C3C",
        secondary: "#00000070",
        progress: "#15461980",
        progressBackground: "#E7DCD2"
      }
    },
    typography: {
      fontFamily: 'Ubuntu'
    }
  },
    deDE
  );

  const initalReportConfig = {
    "email": "",
    "date": moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0),
    "content": "",
    "attachments": [],
    "contracts": [],
    "loading": false
  };

  const { events, signinSilent, signoutSilent } = useAuth();

  const reportRef = useRef(initalReportConfig);

  useEffect(() => {
    return () => {
      events.addAccessTokenExpiring(() => {
        signinSilent()
          .then(() => reportRef.current = { ...reportRef.current })
          .catch(err => {
            if (err.response?.status === 400) {
              signoutSilent();
            }
          });
      })
    };
    //eslint-disable-next-line
  }, [events, signinSilent, signoutSilent]);

  const Redirect = () => {
    return <Navigate replace to="/login" />;
  };

  return (
    <BrowserRouter>
      <ClaimProvider>
        <ThemeProvider theme={theme}>
          <Nav />
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/report" element={<PrivateRoute><Report reportRef={reportRef} /></PrivateRoute>} />
            <Route exact path="/complete" element={<PrivateRoute><Complete reportRef={reportRef} /></PrivateRoute>} />
            <Route exact path="/claims" element={<PrivateRoute><Claims reportRef={reportRef} /></PrivateRoute>} />
            <Route exact path="*" element={<Redirect />} />
          </Routes>
        </ThemeProvider >
      </ClaimProvider>
    </BrowserRouter>
  );
}

export default App;