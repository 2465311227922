import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimContext } from "./ClaimContext";

export const useConvertDto = () => {

    const { t } = useTranslation();

    const { claim } = useContext(ClaimContext);

    const getClaimIdentification = () => {
        const relevantKeys = [
            {
                api: "policyNumber",
                internal: "policy"
            },
            {
                api: "claimNumber",
                internal: "claimNumber"
            },
            {
                api: "reporter",
                internal: "reporter"
            },
            {
                api: "contactEmail",
                internal: "contactEmail"
            },
            {
                api: "contactPhone",
                internal: "contactPhone"
            },
            {
                api: "sentAt",
                internal: "sentAt"
            }
        ];
        const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|([+-]\d{2}:\d{2}))?$/;

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.notifications[0]?.hasOwnProperty(key?.api) && claim?.notifications[key?.api] !== null) {
                if (claim?.notifications[0][key.api].match(isoRegex)) {
                    acc[key.api] = {
                        "api": moment(claim.notifications[0][key.api]).format("DD.MM.YYYY HH:mm"),
                        "internal": internal
                    };
                } else {
                    acc[key.api] = {
                        "api": claim.notifications[0][key.api],
                        "internal": internal
                    };
                }
            } else if (internal === "policy" && claim?.hasOwnProperty(key?.api) && claim[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim[key.api],
                    "internal": internal
                };
            } else if (internal === "claimNumber" && claim?.hasOwnProperty(key?.api) && claim[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim[key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("claimIdentification"),
            data: obj
        };
    };

    const getClaimClassification = () => {
        const relevantKeys = [
            {
                api: "messageType",
                internal: "messageType"
            },
            {
                api: "insuranceType",
                internal: "insuranceType"
            },
            {
                api: "insurance",
                internal: "insurance"
            },
            {
                api: "risk",
                internal: "risk"
            },
            {
                api: "cause",
                internal: "cause"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.classification?.hasOwnProperty(key?.api) && claim?.classification[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim?.classification[key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("claimClassification"),
            data: obj
        };
    };

    const getClaimData = () => {
        const relevantKeys = [
            {
                api: "lossDate",
                internal: "lossDate"
            },
            {
                api: "lossTime",
                internal: "lossTime"
            },
            {
                api: "lossLocation",
                internal: "lossLocation"
            },
            {
                api: "damagedObject",
                internal: "damagedObject"
            },
            {
                api: "damagedObjectCategory",
                internal: "damagedObjectCategory"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.classification?.hasOwnProperty(key?.api) && claim?.classification[key?.api] !== null) {
                if (claim?.classification["lossDateExplicit"] === false && (internal === "lossDate" || internal === "lossTime")) {
                    acc[key.api] = {
                        "api": `${claim?.classification[key.api]} (${t("estimated")})`,
                        "internal": internal
                    };
                } else if (claim?.classification["explicitLossLocation"] === false && key.api === "lossLocation") {
                    acc[key.api] = {
                        "api": `${claim?.classification[key.api]} (${t("estimated")})`,
                        "internal": internal
                    };
                } else {
                    acc[key.api] = {
                        "api": claim?.classification[key.api],
                        "internal": internal
                    };
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("claim"),
            data: obj
        };
    };

    const getClaimDescription = () => {
        const relevantKeys = [
            {
                api: "damageSum",
                internal: "damageSum"
            },
            {
                api: "lossDescription",
                internal: "lossDescription"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.classification?.hasOwnProperty(key?.api) && claim?.classification[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim?.classification[key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("claimDescription"),
            data: obj
        };
    };

    const getClaimCar = () => {
        const relevantKeys = [
            {
                api: "licensePlate",
                internal: "carLicensePlate"
            },
            {
                api: "vin",
                internal: "carVin"
            },
            {
                api: "year",
                internal: "carYear"
            },
            {
                api: "mileage",
                internal: "carMileage"
            },
            {
                api: "make",
                internal: "carMake"
            },
            {
                api: "model",
                internal: "carModel"
            },
            {
                api: "color",
                internal: "carColor"
            },
            {
                api: "thirdLicensePlate",
                internal: "carThirdLicensePlate"
            },
            {
                api: "thirdVin",
                internal: "carThirdVin"
            },
            {
                api: "thirdYear",
                internal: "carThirdYear"
            },
            {
                api: "thirdMake",
                internal: "carThirdMake"
            },
            {
                api: "thirdModel",
                internal: "carThirdModel"
            },
            {
                api: "thirdColor",
                internal: "carThirdColor"
            },
            {
                api: "thirdDriverName",
                internal: "carThirdDriverName"
            },
            {
                api: "thirdDriverAddress",
                internal: "carThirdDriverAddress"
            },
            {
                api: "thirdDriverLicense",
                internal: "carThirdDriverLicense"
            },
            {
                api: "thirdDamage",
                internal: "carThirdDamage"
            },
            {
                api: "coverage",
                internal: "coverage"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.vehicle?.hasOwnProperty(key?.api) && claim?.vehicle[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim.vehicle[key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("car"),
            data: obj
        };
    };

    const getClaimPhv = () => {
        const relevantKeys = [
            {
                api: "thirdName",
                internal: "thirdName"
            },
            {
                api: "thirdAddress",
                internal: "thirdAddress"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.liability?.hasOwnProperty(key?.api) && claim?.liability[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim.liability[key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("phv"),
            data: obj
        };
    };

    const getClaimPayment = () => {
        const relevantKeys = [
            {
                api: "paymentIban",
                internal: "paymentIban"
            },
            {
                api: "paymentRecipient",
                internal: "paymentRecipient"
            },
            {
                api: "paymentInfo",
                internal: "paymentInfo"
            },
            {
                api: "paymentAmount",
                internal: "paymentAmount"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.payment?.hasOwnProperty(key?.api) && claim?.payment[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim.payment[key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("payment"),
            data: obj
        };
    };

    const getClaimConfidence = () => {
        //confidences array is taken as is
        //eslint-disable-next-line
        const relevantKeys = [];

        return {
            title: t("confidences"),
            data: claim?.confidences ?? []
        };
    };

    const getClaimOverview = () => {
        const relevantKeys = [
            {
                api: "claim",
                internal: "claim"
            },
            {
                api: "consistent",
                internal: "consistent"
            },
            {
                api: "multipleConcerns",
                internal: "multipleConcerns"
            },
            // {
            //     api: "complex",
            //     internal: "complex"
            // },
            {
                api: "personalInjury",
                internal: "personalInjury"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.flags?.hasOwnProperty(key?.api) && claim?.flags[key?.api] !== null) {
                switch (internal) {
                    case "claim":
                        acc[key.api] = {
                            "api": claim?.flags[key.api] ? t("isClaim") : t("isNotClaim"),
                            "internal": internal,
                            "bool": claim?.flags[key.api]
                        };
                        break;
                    case "multipleConcerns":
                        acc[key.api] = {
                            "api": claim?.flags[key.api] ? t("isMultiple") : t("isNotMultiple"),
                            "internal": internal,
                            "bool": !claim?.flags[key.api]
                        };
                        break;
                    case "consistent":
                        if (claim?.attachments?.length >= 1) {
                            acc[key.api] = {
                                "api": claim[key.api] ? t("isConsistent") : t("isNotConsistent"),
                                "internal": internal,
                                "bool": claim[key.api]
                            };
                        };
                        break;
                    // case "complex":
                    //     if (claim[key.api]) {
                    //         acc[key.api] = {
                    //             "api": t("isComplex"),
                    //             "internal": internal,
                    //             "bool": false
                    //         };
                    //     };
                    //     break;
                    case "personalInjury":
                        if (claim?.flags[key.api]) {
                            acc[key.api] = {
                                "api": t("isPersonalInjury"),
                                "internal": internal,
                                "bool": claim?.flags[key.api]
                            };
                        };
                        break;
                    default:
                        break;
                };
            };
            return acc;
        }, {});

        return {
            title: t("overview"),
            data: obj
        };
    };

    const getCoverageSummary = () => {
        const relevantKeys = [
            {
                api: "insured",
                internal: "insured"
            },
            {
                api: "insuredObject",
                internal: "insuredObject"
            },
            {
                api: "insuredAddress",
                internal: "insuredAddress"
            },
            {
                api: "summary",
                internal: "summary"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.coverage?.hasOwnProperty(key?.api) && claim?.coverage[key?.api] !== null) {
                acc[key.api] = {
                    "api": claim.coverage[key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: t("coverage2"),
            data: obj
        };
    };

    const getCoverageCondition = (index) => {
        const relevantKeys = [
            {
                api: "description",
                internal: "description"
            },
            {
                api: "satisfied",
                internal: "satisfied"
            },
            {
                api: "satisfiedReason",
                internal: "satisfiedReason"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.coverage?.conditions[index]?.hasOwnProperty(key?.api) && claim?.coverage?.conditions[index][key?.api] !== null && index !== undefined) {
                acc[key.api] = {
                    "api": claim.coverage.conditions[index][key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: claim?.coverage?.conditions[index]?.title ?? t("missingCondition"),
            data: obj
        };
    };

    const getCoverageLimit = (index) => {
        const relevantKeys = [
            {
                api: "deductible",
                internal: "deductible"
            },
            {
                api: "maxInsuredSum",
                internal: "maxInsuredSum"
            },
            {
                api: "description",
                internal: "description"
            }
        ];

        const obj = relevantKeys.reduce((acc, key, i) => {
            const internal = key?.internal ?? t("missingInternal");
            if (key?.api && claim?.coverage?.limits[index]?.hasOwnProperty(key?.api) && claim?.coverage?.limits[index][key?.api] !== null && index !== undefined) {
                acc[key.api] = {
                    "api": claim.coverage.limits[index][key.api],
                    "internal": internal
                };
            } else {
                acc[key?.api ?? i] = {
                    "api": t("notFound"),
                    "internal": internal
                };
            };
            return acc;
        }, {});

        return {
            title: claim?.coverage?.limits[index]?.title ?? t("missingLimit"),
            data: obj
        };
    };

    return { getClaimIdentification, getClaimClassification, getClaimData, getClaimDescription, getClaimCar, getClaimPhv, getClaimPayment, getClaimConfidence, getClaimOverview, getCoverageSummary, getCoverageCondition, getCoverageLimit };

};