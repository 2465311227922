import { useAuth } from "react-oidc-context";
import { Navigate, useLocation } from "react-router-dom";

const validPaths = ["/report", "/complete", "/claims"];

export default function PrivateRoute({ children }) {

    const { isAuthenticated } = useAuth();

    const location = useLocation();

    const handleRedirect = () => {
        const redirect = `${location?.pathname ?? ""}${location?.search ?? ""}`;
        if (validPaths.find(p => p === window?.location?.pathname ?? "")) {
            localStorage.setItem("redirect", redirect);
        }
        return <Navigate replace to="/login" />
    };

    return isAuthenticated ? children : handleRedirect();
};